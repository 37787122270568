<template>
	<div class="achieve-form-setting-wrapper">
		<Spin fix v-if="loading"></Spin>
		<div class="ctms-form-design">
			<div class="form-component-group">
				<h2>表单组件</h2>
				<Input
					icon="ios-search"
					placeholder="过滤表单组件"
					clearable
					class="filter-search"
					v-model="filterData"
					:search="true"
				/>
				<div class="component-list">
					<ul :style="`height: ${contentHeight}px;overflow:auto`">
						<li
							v-for="item in noChosenList"
							:key="item.id"
							@click="handlenoChosenItemClick(item)"
						>
							<Tooltip>
								<div slot="content">
									<p style="white-space: normal">
										{{ item.tag ? item.tag + " -- " : item.tag }}{{ item.name }}
									</p>
								</div>
								<Button
									style="
										width: 320px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									"
								>
									{{ item.tag ? item.tag + " -- " : item.tag
									}}{{ item.name }}</Button
								>
							</Tooltip>
						</li>
					</ul>
				</div>
			</div>
			<div class="form-component-group">
				<h2>
					已选组件
					<Button
						style="margin-left: 30px; padding: 4px 35px"
						type="primary"
						@click="onOk"
						:loading="loading"
						>保存配置
					</Button>
					<Button style="margin-left: 30px; padding: 4px 35px" @click="goback"
						>返回列表
					</Button>
				</h2>
				<designTemplate
					ref="designTemplate"
					:componentList.sync="componentList"
					showType="design"
					@onDeleItem="handleDeleChosen"
				></designTemplate>
			</div>
		</div>
	</div>
</template>

<script>
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"
import api from "@/api/achievement/achievement"
import formItemApi from "@/api/setting/formItem"
import { mapState } from "vuex"

const { apiGetFormConfig, apiSaveFormConfig } = api
const { getFormItemList } = formItemApi
export default {
	name: "AchieveFormDesign",
	components: {
		designTemplate
	},
	data() {
		return {
			loading: false,
			formItemList: [],
			allFormItemList: [],
			componentList: [],
			filterData: ""
		}
	},
	computed: {
		noChosenList() {
			if (!this.filterData) {
				return this.formItemList
			}
			return this.formItemList.filter(
				item =>
					item.name.indexOf(this.filterData) > -1 ||
					item.tag.indexOf(this.filterData) > -1
			)
		},
		...mapState({
			contentHeight: state => state.contentHeight - 90
		})
	},
	created() {
		this.getList()
	},
	methods: {
		async getList() {
			this.loading = true
			const res1 = await apiGetFormConfig(this.$route.query.id)
			if (res1) {
				const { json } = res1.data
				if (json) {
					this.componentList = JSON.parse(json)
				}
			}
			// del: 1，查禁用，0查启用，不传，查所有的。
			// isProject 1,只显示已选择是否项目信息 是，并配置过全局唯一编码的表单项。
			const res2 = await getFormItemList({ del: 0, isProject: 1 })
			if (res2) {
				const { data } = res2
				const formItemList = data
				this.allFormItemList = data
				this.formItemList = formItemList.filter(item => {
					const flag = this.componentList.every(comp => comp.id !== item.id)
					return flag
				})
			}
			this.loading = false
		},
		handlenoChosenItemClick(formItem) {
			// 左侧添加到右侧
			this.$refs.designTemplate.onAddFormItem(formItem)
			const formList = this.formItemList.filter(item => item.id !== formItem.id)
			this.formItemList = [...formList]
		},
		handleDeleChosen(formItem) {
			//  右侧删除组件到左侧
			const newItem = this.allFormItemList.find(item => item.id === formItem.id)
			this.formItemList.push(newItem)
		},
		async onOk() {
			const { list } = this.$refs.designTemplate
			if (!list.length) {
				this.$Message.error("请至少选择一个组件，表单不能为空")
				return
			}
			this.loading = true
			const data = list.map(item => {
				const obj = {}
				Object.keys(item).forEach(key => {
					if (key === "json") {
						return
					}
					obj[key] = item[key]
				})
				return obj
			})
			const res = await apiSaveFormConfig({
				componentIdList: data.map(item => item.id),
				json: JSON.stringify(data),
				formId: this.$route.query.id
			})
			if (res) {
				this.$Message.success("操作成功！")
				this.$router.push({
					name: "settingAchieveFormList"
				})
			}
			this.loading = false
		},
		goback() {
			this.$router.push({
				name: "settingAchieveFormList"
			})
		}
	}
}
</script>

<style scoped lang="less">
.achieve-form-setting-wrapper {
	height: 100%;
	background-color: #fff;
	padding: 16px;
	.ctms-form-design {
		display: flex;
		/*height: ~'calc(100% - 48px)';*/
		.form-component-group {
			padding-right: 10px;
			.component-list {
				padding: 15px;
				/*overflow-y: auto;*/
			}
			h2 {
				font-size: 16px;
				padding: 0 10px;
			}
			&:first-child {
				border-right: 1px solid #ddd;
				width: 390px;
			}
			&:last-child {
				flex: 1;
				overflow: auto;
				h2 {
					color: #f60;
					font-size: 20px;
					margin-bottom: 15px;
				}
			}
			ul {
				list-style: none;
				li {
					margin-top: 16px;
				}
			}
		}
	}
	.footer-wrapper {
		text-align: right;
		padding-right: 20px;
		margin-top: 16px;
	}
}
</style>
